// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$flrhub-md-pallet: (
  50: #f6f0ec,
  100: #e9dacf,
  200: #dac1b0,
  300: #cba890,
  400: #c09678,
  500: #b58360,
  600: #ae7b58,
  700: #a5704e,
  800: #9d6644,
  900: #8d5333,
  A100: #ffe5d8,
  A200: #ffc3a5,
  A400: #ffa172,
  A700: #ff9158,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$flurhub-website-primary: mat.define-palette($flrhub-md-pallet);
$flurhub-website-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$flurhub-website-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$flurhub-website-theme: mat.define-light-theme(
  (
    color: (
      primary: $flurhub-website-primary,
      accent: $flurhub-website-accent,
      warn: $flurhub-website-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($flurhub-website-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,300&display=swap");

/* Pras's Mixins */
// Transitions
$main-transition-time: 300ms;
$main-transition-type: ease-in-out;

@mixin transition(
  $transition: all,
  $mtime: $main-transition-time,
  $mtype: $main-transition-type
) {
  transition-property: $transition;
  transition-duration: $mtime;
  transition-timing-function: $mtype;
}

@mixin bp($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin bpm($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin bp-between($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin bph($point) {
  @media (max-height: $point) {
    @content;
  }
}

:root {
  // Colors
  --grey: #434240;
  --lighter-grey: #6c6c6c;
  --light-grey: #b5b4b2;
  --orange: #b58360;
  --red: #be5a5a;
  --green: #5aac7a;
  --flrhub-green: #13363E;
  --flrhub-orange: #F9803A;

  --mobile-tab-height: 65px;

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  //background: var(--grey);
  background: var(--flrhub-green);

  // Landing Page
  --header-height: 100px;
  --light-light-grey: #efefef;
  --white-gradient: #fff linear-gradient(161deg, #ffffff 0%, #e8e8e8 100%) 0% 0%
    no-repeat padding-box;
  --orange-hover: #FFECE1;
  --section-pad: 180px;
  @include bp(1199px) {
    --header-height: 75px;
    --section-pad: 100px;
  }
  @include bph(650px) {
    --section-pad: 100px;
  }
  @include bp(767px) {
    --header-height: 55px;
    --section-pad: 70px;
  }
}

@mixin for-phone {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin not-for-phone {
  @media (min-width: 1001px) {
    @content;
  }
}

@include for-phone {
  .show-phone {
    display: block;
  }
  .hide-phone {
    display: none !important;
  }
}

@include not-for-phone {
  .show-phone {
    display: none !important;
  }
  .hide-phone {
    display: block;
  }
}

html {
  margin: 0;
  overflow: hidden;
}

body {
  margin: 0;
  overflow: hidden;
}

* {
  font-family: "Poppins";
  color: var(--grey);
}

h1 {
  font-size: 24px;
  font-weight: var(--font-weight-normal);
}

p {
  font-size: 14px;
  font-weight: var(--font-weight-normal);
}

.error {
  color: var(--red);
  font-size: 14px;
  margin-bottom: 14px;
  .error-icon {
    height: 11px;
    width: 11px;
    margin-right: 10px;
  }
}

.empty-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  .message {
    font-size: 24px;
    opacity: 0.26;
    margin-bottom: 16px;
    text-align: center;
  }
}

.spacer {
  flex: 1;
}

.tag {
  background-color: #dddddc;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 11px;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
  text-align: center;
  &.green {
    background-color: var(--green);
    color: white;
  }
  &.red {
    background-color: var(--red);
    color: white;
  }
}

.coming-soon {
  color: var(--flrhub-orange);
  border: 1px solid var(--flrhub-orange);
  border-radius: 16px;
  padding: 2px 6px;
  font-size: 9px;
  margin-left: 8px;
}

.page-container {
  background-color: var(--grey);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  @include for-phone {
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100% !important;
    height: -webkit-fill-available !important;
    min-height: -webkit-fill-available;
    border-radius: 0 !important;
    padding-left: 0px !important;
    app-menu {
      order: 1;
    }
    & > .logo {
      height: 40px !important;
      left: 16px !important;
      top: 85px !important;
    }
  }
  .top-logo-container {
    width: 100%;
    height: 150px;
    background-color: #f8f8f8;
    position: relative;
    @include for-phone {
      height: 95px;
    }
    .logo {
      bottom: 20px !important;
      left: 16px;
      top: unset;
    }
  }
  .inner-container {
    @include for-phone {
      padding: 16px !important;
      box-sizing: border-box;
      margin-right: 0 !important;
    }
  }
  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: white;
    overflow: hidden !important;
    height: 100vh;
    @include for-phone {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .content {
      @include for-phone {
        height: calc(100vh - var(--mobile-tab-height) - 71px - 39px);
        box-sizing: border-box;
        overflow-x: hidden !important;
        padding: 16px 16px 128px 16px;
      }
    }
  }
}

.background-logo {
  @include for-phone {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// INPUT FIELD STYLES
textarea,
input {
  outline: none;
  border: none;
}

.radio-choice {
  display: flex;
  font-size: 14px;
  margin: 8px 0;
  &,
  * {
    box-sizing: border-box;
  }
  .radio-item {
    margin-right: 20px;
    input {
      position: absolute;
      left: -9999em;
      visibility: hidden;
      &:checked + label:after {
        transform: scale(1);
        opacity: 1;
      }
    }
    label {
      font-size: 1.05em;
      font-weight: 400;
      position: relative;
      padding-left: 26px;
      padding-top: 2px;
      cursor: pointer;
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      &::before {
        border: 1px solid #707070;
      }
      &:after {
        width: 14px;
        height: 14px;
        left: 4px;
        top: 4px;
        background-color: var(--flrhub-orange);
        transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
        opacity: 0;
        transform: scale(0);
      }
    }
  }
}

.input-field-row {
  display: flex;
  @include for-phone {
    flex-direction: column;
  }
  & > :first-child {
    @include not-for-phone {
      margin-right: 8px;
    }
    flex: 1;
  }
  & > :last-child {
    @include not-for-phone {
      margin-left: 8px;
    }
    flex: 1;
  }
}

.input-field {
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 35px;
  width: 100%;
  position: relative;
  &.error {
    border-bottom-color: var(--red);
    input::placeholder {
      color: var(--red);
      opacity: 0.4;
    }
    &::after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: 11px;
      height: 14px;
      width: 14px;
      background-image: url("/assets/images/error_icon.png");
      background-size: 14px;
    }
  }
  &.search-field {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    .icon {
      height: 13px;
      margin-right: 14px;
    }
    input {
      padding: 5px 0;
      &::placeholder {
        font-style: italic;
        font-weight: 300;
      }
    }
  }
  label {
    font-weight: 500;
    font-size: 12px;
    display: block;
  }
  input {
    padding: 8px 0;
    font-size: 14px;
    width: 100%;
  }
  select {
    border: none;
    width: 100%;
    height: 37px;
    outline: none;
  }
  .input-description {
    text-align: left;
    margin-bottom: 10px;
  }
}
.quantity-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  .quantity-field {
    border: none;
    outline: none;
    /*border-bottom: 1px solid var(--grey);*/
    font-size: 22px;
    font-weight: 500;
    padding-left: 4px;
    margin-right: 8px;
    width: 80px;
    &.quantity-error {
      border-bottom-color: var(--red);
      &::placeholder {
        color: var(--red);
        opacity: 0.4;
      }
    }
  }
  .unit {
    font-size: 18px;
    font-weight: 500;
    margin-right: 14px;
  }
  .order-fulfilment-details {
    border-radius: 2px;
    white-space: nowrap;
    //border: 1px solid #e3e3e3;
    font-size: 13px;
    padding: 8px 12px;
    position: relative;
    margin-left: auto;
    margin-right: 0;
    &::after {
      content: " ";
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      width: 6px;
      background-color: var(--flrhub-orange);
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    b {
      font-weight: 500;
    }
  }
}

.custom-mat-selector {
  width: 100%;
  position: relative;
  .selected-options {
    position: absolute;
    left: 0;
    right: 16px;
    bottom: 8px;
    overflow-x: auto;
    display: flex;
    div {
      background-color: rgba(67, 66, 64, 0.15);
      margin-right: 4px;
      white-space: nowrap;
      border-radius: 30px;
      font-size: 13px;
      padding: 4px 13px;
    }
  }
  .mat-select-value-text {
    display: none;
  }
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-flex {
    padding: 0 !important;
  }
  .mat-form-field-infix {
    border: none !important;
  }
  .mat-select {
    height: 23px;
    display: flex;
    align-items: center;
  }
  .mat-select-placeholder {
    color: var(--grey);
    font-size: 13px;
  }
  .mat-select-arrow {
    transform: rotate(225deg) !important;
    width: 5px;
    height: 5px;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-right: none;
  }
}

// BUTTON STYLES
.button-group {
  display: flex;
  div {
    margin-right: 8px;
  }
}

.primary-button {
  font-size: 16px;
  font-weight: var(--font-weight-semibold);
  padding: 10px 26px;
  color: white;
  background-color: var(--flrhub-orange);
  border-radius: 30px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  &.regular-font {
    font-weight: var(--font-weight-normal);
  }
}

.secondary-button {
  font-weight: 500;
  font-size: 13px;
  padding: 8px 20px;
  border: 1px solid rgb(67, 66, 64, 0.19);
  border-radius: 40px;
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  background-color: transparent;
  .icon {
    margin-right: 5px;
    width: 8px;
    &.icon-end {
      margin-right: 0;
      margin-left: 5px;
    }
    &.medium-size {
      width: 9px;
      transform: translateY(1px);
    }
  }
}

.dark-button {
  background-color: #0D2F36;
  color: white;
  img {
    filter: brightness(0) invert(1);
  }
}

.icon-button {
  height: 38px;
  width: 38px;
  background-color: #f0f0f0;
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 20px;
  }
  cursor: pointer;
}

.primary-button.loading,
.secondary-button.loading {
  position: relative;
  pointer-events: none;
  cursor: wait;
  color: transparent;
  img {
    opacity: 0;
  }
  &::before {
    content: " ";
    z-index: 1;
    border-radius: 20px;
    height: 14px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.1);
  }
  &::after {
    content: " ";
    z-index: 1;
    border-radius: 20px;
    height: 13px;
    width: 13px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: loading-circle-animation 1s cubic-bezier(0.8, 0, 0.2, 1) infinite;
    background-color: white;
  }
}

.secondary-button.loading {
  &::before {
    background-color: #f4f4f4;
  }
  &::after {
    background-color: #c6c6c6;
  }
}

.close-icon {
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.75;
  }
}

@keyframes loading-circle-animation {
  0% {
    left: calc(50% + 8px);
  }
  50% {
    left: calc(50% - 8px);
  }
  100% {
    left: calc(50% + 8px);
  }
}

.small-button {
  font-weight: 500;
  font-size: 11px;
  padding: 4px 12px;
  border: 1px solid rgb(67, 66, 64, 0.19);
  border-radius: 40px;
  width: fit-content;
  cursor: pointer;
  background-color: transparent;
}

.red-button {
  background-color: var(--red);
  color: white;
  border-color: var(--red);
}

.button-link {
  font-size: 12px;
  color: var(--flrhub-orange);
  text-decoration: none;
  cursor: pointer;
}

// TABLE STYLES
.primary-table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  thead {
    position: sticky;
    top: 0;
    background-color: white;
    &.hide-phone {
      display: table-header-group;
    }
    &::after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: rgba(237, 237, 237, 0.39);
    }
    th {
      font-weight: 500;
      font-size: 12px;
      opacity: 0.41;
      text-align: left;
      padding-top: 26px;
      padding-bottom: 10px;
      &:first-child {
        padding-left: 26px;
      }
      &:last-child {
        padding-right: 26px;
      }
    }
  }
  tbody {
    tr {
      height: 92px;
      cursor: pointer;
      @include for-phone {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 16px;
        width: 100vw;
        box-sizing: border-box;
      }
      td {
        font-size: 13px;
        font-weight: 500;
        padding-right: 24px;
        @include for-phone {
          padding: 0 !important;
        }
        &.bold {
          font-weight: 600;
        }
        &.underline {
          text-decoration: underline;
        }
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 26px;
        }
      }
      &:nth-child(odd) {
        background-color: rgba(237, 237, 237, 0.39);
      }
    }
  }
}

// SELECT STYLES
.select-boxes {
  .select-box {
    margin-bottom: 8px;
  }
}
.select-box {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 75px;
  border-radius: 6px;
  border: 1px solid #b5b7c3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @include for-phone {
    padding: 0 16px;
  }
  * {
    transition: all 0.2s ease-in-out;
  }
  .select-description {
    flex: 1;
    margin-right: 24px;
    .title {
      font-weight: 500;
    }
    .description {
      font-size: 12px;
      opacity: 0.5;
    }
  }
  &.selected {
    background-color: #f7efea;
    border-color: var(--flrhub-orange);
    .check-box:not(.not-selected) {
      border-color: var(--flrhub-orange);
      background-color: var(--flrhub-orange);
      img {
        opacity: 1;
      }
    }
  }
}

.check-box-container {
  display: flex;
  align-items: center;
  .check-box {
    margin-right: 10px;
  }
}

.check-box {
  border-radius: 4px;
  border: 1px solid #707070;
  height: 22px;
  width: 22px;
  min-width: 22px;
  position: relative;
  img {
    opacity: 0;
    width: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.selected {
    border-color: var(--flrhub-orange);
    background-color: var(--flrhub-orange);
    img {
      opacity: 1;
    }
  }
}

.toggle-box {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 75px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  @include for-phone {
    padding: 0 16px;
  }
  * {
    transition: all 0.2s ease-in-out;
  }
  .toggle {
    width: 54px;
    height: 33px;
    border-radius: 60px;
    border: 1px solid var(--flrhub-orange);
    background-color: #f5f5f5;
    position: relative;
    .thumb {
      position: absolute;
      top: -1px;
      left: -1px;
      height: 33px;
      width: 33px;
      border-radius: 60px;
      border: 1px solid var(--flrhub-orange);
      background-color: white;
      img {
        height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .check {
        opacity: 0;
        width: 15px;
        object-fit: contain;
      }
      .cross {
        opacity: 1;
      }
    }
  }
  .select-description {
    flex: 1;
    margin-left: 20px;
    @include for-phone {
      margin-left: 16px;
    }
    .title {
      font-weight: 400;
      b {
        font-weight: 500;
      }
    }
    .description {
      font-size: 12px;
      opacity: 0.5;
    }
  }
  &.selected {
    background-color: #f7eae1;
    border-color: var(--flrhub-orange);
    color: var(--flrhub-orange);
    .toggle {
      background-color: var(--flrhub-orange);
      .thumb {
        left: 20px;
      }
      .check {
        opacity: 1 !important;
      }
      .cross {
        opacity: 0 !important;
      }
    }
    .select-description {
      .title {
        color: var(--flrhub-orange);
      }
    }
  }
  &.small-toggle {
    height: 58px;
    .title {
      font-size: 12px;
    }
  }
}

// IMAGE UPLOAD

.upload-image {
  border-radius: 6px;
  border: 1px solid #b5b7c3;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include not-for-phone {
    max-width: calc(50% - 44px - 8px) !important;
  }
  @include for-phone {
    margin-bottom: 16px;
  }
  .preview-image-section {
    .image-title {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    .preview-image {
      border: 1px dashed #acacac;
      border-radius: 2px;
      padding: 15px;
      height: 70px;
      width: 70px;
      object-fit: contain;
    }
  }
  .input-section {
    margin-left: 22px;
    .file-input {
      display: none;
    }
  }
}
.input-description {
  margin-top: 10px;
  font-size: 11px;
  opacity: 0.6;
  text-align: center;
  &.size-error {
    color: var(--red);
  }
}

// MODAL STYLES
.profile-modal {
  .mat-dialog-container {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    // transform: translateY(100%);
    animation: pull-up 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    box-shadow: none;
    overflow: hidden !important;
    @include for-phone {
      padding: 0px;
    }
  }
  &.no-padding {
    .mat-dialog-container {
      padding: 0;
    }
  }
  &.animate-out {
    .mat-dialog-container {
      animation: pull-down 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
  &.no-animation {
    .mat-dialog-container {
      animation: none !important;
    }
  }
  &.grey-bg {
    .mat-dialog-container {
      background-color: #f8f8f8;
    }
  }
}

.choose-product-modal {
  .mat-dialog-container {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    // transform: translateY(100%);
    animation: pull-up 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    box-shadow: none;
    overflow: hidden;
  }
  &.no-padding {
    .mat-dialog-container {
      padding: 0;
    }
  }
  &.animate-out {
    .mat-dialog-container {
      animation: pull-down 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
}

.product-details-modal {
  .mat-dialog-container {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: none;
    overflow: hidden;
  }
  &.no-padding {
    .mat-dialog-container {
      padding: 0;
    }
  }
  &.animate-out {
    .mat-dialog-container {
      animation: pull-down 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
}

.alert-modal {
  .mat-dialog-container {
    box-shadow: none;
  }
  &.no-padding {
    .mat-dialog-container {
      padding: 0;
    }
  }
}

// TABS STYLES
.tabs {
  display: flex;
  align-items: center;
  padding: 0 28px;
  border-bottom: 1px solid #f0f0f0;
  @include for-phone {
    padding: 0 16px;
  }
  .items {
    color: #969594;
    margin-right: 38px;
    font-size: 13px;
    padding: 8px 0;
    cursor: pointer;
    &.active {
      color: var(--flrhub-orange);
      border-bottom: 2px solid var(--flrhub-orange);
    }
  }
}

@keyframes pull-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes pull-down {
  0% {
    transform: translateY(0%);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/** GLOBAL **/
.page-wrapper {
  height: 100vh;
  overflow: auto;

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    backface-visibility: hidden;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .boxed {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 0 1px var(--grey);
    overflow: hidden;
    margin-bottom: 1px;
  }
  video {
    display: block;
  }

  /** TYPOGRAPHY **/
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
    font-weight: 400;
  }
  h1,
  .h1 {
    font-size: 50px;
    line-height: 1.2;
    @include bp(1199px) {
      font-size: 40px;
    }
    @include bp(767px) {
      font-size: 32px;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 1.4;
    @include bp(1199px) {
      font-size: 22px;
    }
    @include bp(767px) {
      font-size: 20px;
    }
  }
  p {
    line-height: 1.7;
  }

  /** GLOBAL **/
  a {
    text-decoration: none;
    @include transition((color, background-color, border-color));
    @media (pointer: fine) {
      &:hover {
        color: var(--orange-hover);
      }
      &.primary-button:hover {
        color: #fff;
      }
    }
  }
  .primary-button {
    @media (pointer: fine) {
      &:hover {
        background-color: var(--orange-hover);
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }

  .ul-reset {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .color-white {
    &,
    * {
      color: #fff;
    }
  }
  .page-content {
    padding-top: var(--header-height);
    p {
      margin-top: 0;
    }
  }
}

/** HEADER **/
#masthead {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1px 1px 0;
  width: 100%;
  height: var(--header-height);
  z-index: 500;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    display: block;
    background-color: var(--grey);
  }
  .inner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    white-space: nowrap;
    position: relative;
    z-index: 5;
    &.center-logo {
      justify-content: center;
    }
    @include bp(1199px) {
      padding: 0 25px;
    }
    @include bp(767px) {
      padding: 0 15px;
    }
  }
  ul {
    li {
      font-weight: 500;
      display: inline-block;
      vertical-align: middle;
      margin-left: 40px;
      @include bp(1199px) {
        margin-left: 25px;
      }
      &:first-child {
        margin-left: 0 !important;
      }
      a {
        font-size: 14px;
      }
    }
  }
  img {
    display: block;
    @include bp(767px) {
      height: 25px;
      width: auto;
    }
  }
  .primary {
    padding-left: 70px;
    @include bp(1199px) {
      padding-left: 45px;
    }
    @include bp(767px) {
      display: none;
    }
  }
  .secondary {
    margin-left: auto;
    .primary-button {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

/** PRICING REUSE **/
.row-pricing {
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(2) {
    margin-right: 100px;
    @include bp(1199px) {
      margin-right: 50px;
    }
    @include bp(767px) {
      padding-right: 15px;
    }
  }
  > div:nth-child(3) {
    padding-left: 15px;
  }
  .pricing-context {
    width: calc(100% - 500px);
    @include bp(1199px) {
      width: calc(100% - 450px);
    }
    @include bp(991px) {
      width: calc(100% - 360px);
    }
    @include bp(767px) {
      width: 100%;
    }
  }
  &.heading {
    @include bp(767px) {
      .pricing-options {
        text-align: left;
      }
    }
  }
  .pricing-options {
    width: 200px;
    text-align: center;
    @include bp(991px) {
      width: 155px;
    }
    @include bp(767px) {
      width: 50%;
      margin-right: 0 !important;
    }
    .head {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      flex-direction: column;
      @include bp(767px) {
        text-align: left;
      }
      > * {
        width: 100%;
        &.foot-price {
          margin-top: auto;
        }
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 1.2;
      }
      p {
        font-size: 12px;
        opacity: 0.6;
        margin-bottom: 0;
      }
      .price-tba {
        margin: 0.4em;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
        span {
          display: block;
          opacity: 0.5;
          font-size: 12px;
        }
      }
      .price {
        font-size: 60px;
        padding-left: 0.35em;
        margin: 0.4em 0;
        font-weight: 500;
        position: relative;
        line-height: 1;
        display: inline-block;
        @include bp(991px) {
          font-size: 55px;
        }

        @include bp(767px) {
          font-size: 45px;
          padding-left: 0.3em;
        }
        .symbol {
          position: absolute;
          top: 4px;
          left: 0;
          font-size: 50%;
          font-weight: 400;
        }
        .small {
          font-size: 0.4em;
          font-weight: 600;
        }
        .duration {
          font-size: 0.3em;
          font-weight: 300;
          text-align: right;
          @include bp(767px) {
            text-align: left;
            transform: translateX(-1em);
          }
        }
      }
    }
    .checkmark {
      width: 25px;
      height: 19px;
      display: inline-block;
      background-repeat: no-repeat;
      background-image: url(/assets/images/landing/checkmark-grey.svg);
      background-position: center left;
      &.no {
        background-position: center right;
      }
    }
  }
}

/** STICKY PRICING **/
.sticky-pricing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1px;
  z-index: 450;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  @include bpm(768px) {
    display: none;
  }
  &.appear {
    .inner-sticky {
      transform: translateY(0);
    }
  }
  .boxed {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 0 0 1px var(--grey);
    overflow: hidden;
    margin-bottom: 1px;
  }

  /// sticky
  .inner-sticky {
    position: relative;
    width: 100%;
    left: 0;
    top: var(--header-height);
    transform: translateY(-200%);
    //@include transition(( transform ));
    transition: transform 500ms ease-out;
    h3 {
      margin-top: 0;
      margin-bottom: 5px;
    }
    .pricing-context {
      @include bp(767px) {
        display: none;
      }
      h2 {
        display: none;
      }
    }
    .price {
      margin-top: 0 !important;
      @include bp(767px) {
        font-size: 35px;
      }
    }
    .pricing-options .head p {
      display: none;
    }
    .container {
      padding: 15px;
    }
  }
}

/** FOOTER **/
.section-footer {
  padding-bottom: 25px;
  font-size: 12px;
  @include bp(767px) {
    font-size: 10px;
  }
  .container {
    padding-top: 130px;
    padding-bottom: 130px;
    &.flex {
      display: flex;
      justify-content: space-around;
      @include bp(767px) {
        display: initial;
        .contact {
          margin: 15px;
        }
        .contact-address {
          margin: 15px;
        }
      }
    }
    .contact {
      flex: 2;
    }
    .contact-address {
      flex: 1.2;
    }
  }
  .copyright-lines {
    padding: 0 40px;
    @include bp(1199px) {
      padding: 0 25px;
    }
    @include bp(767px) {
      padding: 10px 15px;
    }
    .inner {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      align-items: end;
      > * {
        width: 33.33%;
        @include bp(991px) {
          width: 100%;
        }
      }
    }
    .copy {
      @include bp(991px) {
        order: 2;
      }
    }
    .links {
      text-align: center;
      @include bp(991px) {
        order: 1;
        margin-bottom: 15px;
        text-align: left;
      }
      ul {
        font-size: 0;
        li {
          font-size: 12px;
          display: inline-block;
          vertical-align: middle;
          @include bp(767px) {
            font-size: 10px;
          }
          &:before {
            content: "|";
            display: inline-block;
            margin: 0 5px;
            pointer-events: none;
          }
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .credit {
      text-align: right;
      @include bp(991px) {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  h2 {
    margin-bottom: 15px;
    @include bp(767px) {
      font-size: 35px;
      margin-bottom: 30px;
    }
  }
  h3 {
    font-size: 32px;
    @include bp(767px) {
      font-size: 26px;
    }
    a {
      color: var(--flrhub-orange) !important;
      @media (pointer: fine) {
        &:hover {
          color: var(--orange-hover) !important;
        }
      }
    }
  }
  em {
    color: inherit;
  }
}

/** GSAP **/
section:not(.reveal-blocks) {
  position: relative;
  z-index: 5;
}

.reveal-blocks {
  position: relative;
  z-index: 1;
  transform: translateY(-100%);
  will-change: transform;
  &:not(.has-pointer) {
    //pointer-events: none;
  }
}

html.has-scroll-trigger {
  &,
  body {
    overflow: auto;
  }
  .page-wrapper {
    height: auto;
  }
}

// slider
@import "../src/app/pages/landing/glider.scss";

.field-counter {
  counter-reset: field-counter;
  .counter-item {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    &:before {
      counter-increment: field-counter;
      content: counter(field-counter) ".";
      min-width: 15px;
    }
    .input-field {
      margin-bottom: 0;
      padding-left: 5px;
    }
  }
}

.map-material-job-board {
  &,
  * {
    box-sizing: border-box;
  }
  .map-item {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    .name {
      width: calc(100% - 270px);
      padding-right: 30px;
      display: flex;
      flex-wrap: wrap;
      @include bp(767px) {
        width: 100%;
        margin-bottom: 5px;
      }
      .img {
        width: 45px;
        height: 45px;
        border-radius: 4px;
        overflow: hidden;
        img {
          display: block;
        }
      }
      .detail {
        display: flex;
        align-self: center;
        width: calc(100% - 45px);
        padding-left: 15px;
      }
    }
    .input {
      width: 270px;
      align-self: flex-start;
      overflow: hidden;
      @include bp(767px) {
        margin-top: 5px;
        width: 100%;
        &:before {
          content: "Total m²/lm";
          display: block;
          width: 100%;
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 500;
          opacity: 0.4;
        }
      }
    }
    span,
    input {
      padding: 10px;
      font-size: 16px;
      width: 100%;
      border: none;
      background-color: #fff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      display: block;
      min-height: 47px;
    }
    &:nth-of-type(odd) {
      background-color: #f8f8f8;
    }
    &.item-custom {
      width: 100%;
      padding: 10px 0;
      background-color: transparent;
      @include bp(767px) {
        margin-top: 20px;
      }
      .name {
        @include bp(767px) {
          padding-right: 0;
        }
      }
      .img {
        @include bp(767px) {
          display: none;
        }
      }
      .detail {
        padding-left: 0 !important;
        width: 100% !important;
      }
    }
    &.error {
      margin-bottom: 0;
      .detail {
        color: var(--red);
      }
    }
  }
}

.visually-hidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
 }
